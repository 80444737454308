/* Eksisterende stiler */
div.cdsResponseContainer {
  position: fixed;
  bottom: 0;
  margin-left: -6px;
  width: 45%;
  z-index: 1000; /* Sørger for at containeren ligger over annet innhold */
}

/* Stiler for card-header */
div.cdsResponseContainer div.card-header {
  background-color: rgb(195, 216, 228);
  color: #333;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

/* Stil for den øverste overskriften i card-header */
div.cdsResponseContainer div.card-header span {
  font-size: 1.3rem; /* Større fontstørrelse for øverste overskrift */
  font-weight: bold; /* Gjør teksten fet for å skille den fra tittelen under */
  margin-right: auto; /* Sørger for at tittelen ikke kleber seg til lukkeknappen */
  color: #595959;
}

/* Stil for advarselsikonet med økt spesifisitet for å sikre at stilen anvendes */
div.cdsResponseContainer div.card-header .cdsResponseWarningIcon {
  color: #ff7b07; /* Justert farge for advarselsikonet */
  margin-right: 13px; /* Justert avstand til teksten */
  font-size: 1.6rem; /* Justert størrelse på ikonet */
}

/* Stil for hovedinnholdet i kortet */
div.cdsResponseContainer .card-body {
  padding: 10px; /* Juster etter behov */
  background-color: #fff; /* Bakgrunnsfarge for kortet */
}

/* Stil for detaljer om risiko/bivirkninger */
div.cdsResponseContainer .cdsResponseDetail {
  margin-bottom: 15px; /* Gir avstand til neste seksjon */
}

/* Gjør markeringene av "Risiko/bivirkninger:" og "Alternativer/tiltak:" mindre fremtredende */
div.cdsResponseContainer .cdsResponseDetail strong {
  color: #595959; /* Gjør fargen litt svakere */
}

/* Stil for tittelen "Warfarin" */
div.cdsResponseContainer .cdsResponseMainTitle {
  font-size: 1.6rem; /* Øker fontstørrelsen */
  font-weight: bold; /* Gjør teksten fet */
  margin-top: 10px; /* Legger til mellomrom over tittelen */
  margin-bottom: 10px; /* Legger til mellomrom under tittelen */
}

/* Stil for kilde-seksjonen for å inkludere avstand */
div.cdsResponseContainer .cdsResponseFooter {
  font-size: 0.8rem; /* Mindre tekst for kilden */
  margin-top: 15px; /* Avstand til elementene over */
  /*text-align: center;*/
}

div.cdsResponseContainer .cdsResponseFooter span {
  margin-right: 4px; /* Legger til litt plass mellom "Kilde:" og hyperlinken */
}

/* Stil for lenken i kilde-seksjonen */
div.cdsResponseContainer .cdsResponseLink {
  color: #999; /* Blå farge for lenker */
  text-decoration: none; /* Ingen understrekning */
  font-weight: bold; /* Gjør teksten fet */
}

div.cdsResponseContainer .cdsResponseLink:hover {
  text-decoration: underline; /* Understrekning ved hover */
}

/* Ny stil for tjenesteleverandør-teksten for å sikre at den vises på en egen linje */
div.cdsResponseContainer .cdsResponseProvider {
  color: #999; /* Svakere farge for denne teksten */
  font-size: 0.7rem; /* Mindre skriftstørrelse hvis nødvendig */
  margin-top: 12px; /* Litt plass over */
  margin-bottom: 8px;
  display: block; /* Sikrer at denne div'en vises som et blokkelement */
  /*text-align: center;*/
}


/* Stiler for card-header */
div.cdsModal div.card-header {
  background-color: rgb(195, 216, 228);
  color: #333;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

/* Stil for den øverste overskriften i card-header */
div.cdsModal div.card-header span {
  font-size: 1.3rem; /* Større fontstørrelse for øverste overskrift */
  font-weight: bold; /* Gjør teksten fet for å skille den fra tittelen under */
  margin-right: auto; /* Sørger for at tittelen ikke kleber seg til lukkeknappen */
  color: #595959;
}

/* Stil for advarselsikonet med økt spesifisitet for å sikre at stilen anvendes */
div.cdsModal div.card-header .cdsResponseWarningIcon {
  color: #ff7b07; /* Justert farge for advarselsikonet */
  margin-right: 13px; /* Justert avstand til teksten */
  font-size: 1.6rem; /* Justert størrelse på ikonet */
}

/* Stil for hovedinnholdet i kortet */
div.cdsModal .card-body {
  padding: 10px; /* Juster etter behov */
  background-color: #fff; /* Bakgrunnsfarge for kortet */
}

/* Stil for detaljer om risiko/bivirkninger */
div.cdsModal .cdsResponseDetail {
  margin-bottom: 15px; /* Gir avstand til neste seksjon */
}

/* Gjør markeringene av "Risiko/bivirkninger:" og "Alternativer/tiltak:" mindre fremtredende */
div.cdsModal .cdsResponseDetail strong {
  color: #595959; /* Gjør fargen litt svakere */
}

/* Stil for tittelen "Warfarin" */
div.cdsModal .cdsResponseMainTitle {
  font-size: 1.2rem; /* Øker fontstørrelsen */
  font-weight: bold; /* Gjør teksten fet */
  margin-top: 10px; /* Legger til mellomrom over tittelen */
  margin-bottom: 10px; /* Legger til mellomrom under tittelen */
}

/* Stil for kilde-seksjonen for å inkludere avstand */
div.cdsModal .cdsResponseFooter {
  font-size: 0.8rem; /* Mindre tekst for kilden */
  margin-top: 15px; /* Avstand til elementene over */
  /*text-align: center;*/
}

div.cdsModal .cdsResponseFooter span {
  margin-right: 4px; /* Legger til litt plass mellom "Kilde:" og hyperlinken */
}

/* Stil for lenken i kilde-seksjonen */
div.cdsModal .cdsResponseLink {
  color: #999; /* Blå farge for lenker */
  text-decoration: none; /* Ingen understrekning */
  font-weight: bold; /* Gjør teksten fet */
}

div.cdsModal .cdsResponseLink:hover {
  text-decoration: underline; /* Understrekning ved hover */
}

/* Ny stil for tjenesteleverandør-teksten for å sikre at den vises på en egen linje */
div.cdsModal .cdsResponseProvider {
  color: #999; /* Svakere farge for denne teksten */
  font-size: 0.7rem; /* Mindre skriftstørrelse hvis nødvendig */
  margin-top: 12px; /* Litt plass over */
  margin-bottom: 8px;
  display: block; /* Sikrer at denne div'en vises som et blokkelement */
  /*text-align: center;*/
}



/* Generelle stiler for desktop */
.modal-content {
  background-color: #fff;
  padding: 0;
  border-radius: 0px;
  max-width: 750px; /* Maks bredde for desktop */
  min-width: 800px; /* Minste bredde for desktop */
}

.modal-dialog {
  display: flex;
  align-items: center; /* Sentraliserer modalen vertikalt */
  justify-content: center; /* Sentraliserer modalen horisontalt */
  min-height: calc(100vh - (16rem * 2)); /* Representerer topp og bunn margin */
}



.text-warning1
{
  color: #ff8903e6 !important;
  font-size: 1.7em; 

}

.text-warning1a
{
  color: #ff8903e6 !important;
  font-size: 1.6em; 
  margin-right: 8px

}



.text-warning2
{
  color: #ff8903e6 !important;
  font-size: 1.7em; 

}

.text-warning2a
{
  color: #ff8903e6 !important;
  font-size:  1.6em; 
  margin-right: 8px

}

.icons-container {
  font-size: 1.5em; /* Juster om nødvendig for å gjøre trekanten mindre */
  position: absolute;
  top: 98%; /* Juster denne for å linje opp med midten av søkefeltet */
  right: 0px; /* Juster dette for å posisjonere boksen mot høyre side av søkefeltet */
  transform: translateY(-50%); /* Dette vil sentrere boksen vertikalt i forhold til søkefeltet */
  
  
  /* Disse kan justeres for å kontrollere størrelsen og plasseringen av boksen rundt trekanten */
  padding: 2px; /* Juster padding for å kontrollere plassen inni boksen */
  width: 80px; /* Juster bredde om nødvendig */
  height: 40px; /* Juster høyde om nødvendig */
  display: flex; /* Dette hjelper med å sentrere ikonet i boksen hvis det er et ikon */
  align-items: center; /* Vertikal sentrering i boksen */
  justify-content: right; /* Horisontal sentrering i boksen */
}

.text-warning1b {
  color: #ff8903e6 !important;
  font-size: 1.3em; /* Juster om nødvendig for å gjøre trekanten mindre */
  right: 1px; /* Juster dette for å posisjonere boksen mot høyre side av søkefeltet */
  transform: translateY(-50%); /* Dette vil sentrere boksen vertikalt i forhold til søkefeltet */
  z-index: 10; /* Sørger for at ikonet legger seg over input-feltet */
  
  /* Disse kan justeres for å kontrollere størrelsen og plasseringen av boksen rundt trekanten */
  /*padding: 10px; /* Juster padding for å kontrollere plassen inni boksen */
  width: 35px; /* Juster bredde om nødvendig */
  height: 35px; /* Juster høyde om nødvendig */
  display: flex; /* Dette hjelper med å sentrere ikonet i boksen hvis det er et ikon */
  align-items: center; /* Vertikal sentrering i boksen */
  justify-content: center; /* Horisontal sentrering i boksen */
} 

.text-kidney1
{

  /* top: 72%; /* Juster denne for å linje opp med midten av søkefeltet */
  right: 2px; /* Juster dette for å posisjonere boksen mot høyre side av søkefeltet */
  transform: translateY(-50%); /* Dette vil sentrere boksen vertikalt i forhold til søkefeltet */
  z-index: 10; /* Sørger for at ikonet legger seg over input-feltet */
  /* Disse kan justeres for å kontrollere størrelsen og plasseringen av boksen rundt trekanten */
  padding: 2px; /* Juster padding for å kontrollere plassen inni boksen */
  width: 35px; /* Juster bredde om nødvendig */
  height: 35px; /* Juster høyde om nødvendig */
  display: flex; /* Dette hjelper med å sentrere ikonet i boksen hvis det er et ikon */
}

.text-kidney2
{

  top: 56%; /* Juster denne for å linje opp med midten av søkefeltet */
  right: 2px; /* Juster dette for å posisjonere boksen mot høyre side av søkefeltet */
  /*transform: translateY(-50%); /* Dette vil sentrere boksen vertikalt i forhold til søkefeltet */
  z-index: 0; /* Sørger for at ikonet legger seg over input-feltet */
  /* Disse kan justeres for å kontrollere størrelsen og plasseringen av boksen rundt trekanten */
  padding: 1px; /* Juster padding for å kontrollere plassen inni boksen */
  width: 30px; /* Juster bredde om nødvendig */
  height: 30px; /* Juster høyde om nødvendig */
  display: flex; /* Dette hjelper med å sentrere ikonet i boksen hvis det er et ikon */
  
}

.text-kidney3
{
  /*top: 60%;  */
  transform: translateY(-3%); /* Dette vil sentrere boksen vertikalt i forhold til søkefeltet */
  /*z-index: 0; /* Sørger for at ikonet legger seg over input-feltet */
  /* Disse kan justeres for å kontrollere størrelsen og plasseringen av boksen rundt trekanten */
  width: 25px; /* Juster bredde om nødvendig */
  height: 25px; /* Juster høyde om nødvendig */
  /*isplay: flex; /* Dette hjelper med å sentrere ikonet i boksen hvis det er et ikon */
  padding: 1px; 
}

.text-kidney4
{
  /*top: 60%;  */
  transform: translateY(5%); /* Dette vil sentrere boksen vertikalt i forhold til søkefeltet */
  /*z-index: 0; /* Sørger for at ikonet legger seg over input-feltet */
  /* Disse kan justeres for å kontrollere størrelsen og plasseringen av boksen rundt trekanten */
  width: 35px; /* Juster bredde om nødvendig */
  height: 35px; /* Juster høyde om nødvendig */
  /*isplay: flex; /* Dette hjelper med å sentrere ikonet i boksen hvis det er et ikon */
  padding: 5px; 
}





.btn-info {
  color: #fff;
  background-color: #fff;;
  border-color: #fff;;
}

.btn-info:hover {
  color: #fff;
  background-color: #c3c5c5;
  border-color: #c3c5c5;
}



/* Mediespørringer for mobil */
@media (max-width: 768px) {
  .modal-content {
    max-width: 90%; /* Maks bredde for mobil */
    min-width: auto; /* Fjern minste bredde for mobil */
    padding: 15px; /* Juster padding for mobil */
  }

  .modal-dialog {
    min-height: calc(100vh - (8rem * 2)); /* Juster høyde for mobil */
  }

  .modal-content .choice-button {
    font-size: 14px; /* Juster fontstørrelse for mobil */
    padding: 8px; /* Juster padding for mobil */
  }
}

@media (max-width: 480px) {
  .modal-content {
    max-width: 95%; /* Maks bredde for mobil */
    min-width: auto; /* Fjern minste bredde for mobil */
    padding: 10px; /* Juster padding for mobil */
  }

  .modal-dialog {
    min-height: calc(100vh - (4rem * 2)); /* Juster høyde for mobil */
  }

  .modal-content .choice-button {
    font-size: 12px; /* Juster fontstørrelse for mobil */
    padding: 6px; /* Juster padding for mobil */
  }
}