.App {
  text-align: left;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.c3 {
  box-shadow: 1px 0px rgba(239, 239, 239, 0.533);
  color: #ffffff;
}

.c1 {
  box-shadow: 1px 0px rgba(239, 239, 239, 0.533);
  margin-bottom: 10px;
  background-color: #154e78;
  color: #ffffff;
}

.c2 {
  box-shadow: 1px 0px rgba(239, 239, 239, 0.533);
  background-color: #154e78;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #ffffff;
}

.form1 {
  margin-top: 5px;
}

.col4 {
  margin-top: 5px;
}

.c5 {
  box-shadow: 1px 0px rgba(239, 239, 239, 0.533);
  background-color: #154e78;
  color: #ffffff;
  margin-bottom: 10px;
}

.date {
  background-color: #154e78;
  color: #ffffff;
  margin-right: 70px;
  width: "100%";
}

.submitbtn {
  margin-right: 70px;
}

.btn-primary {
  --bs-btn-color: #fff;
  background-color: orangered;
  --bs-btn-border-color: #727a86;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #b8c1cf;
  --bs-btn-hover-border-color: #90a7cb;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #91a2bc;
  --bs-btn-active-border-color: #9cacc4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #a7b5c9;
  --bs-btn-disabled-border-color: #92afd9;
}

.btn {
  background-color: orangered;
}

.form-control {
  display: flex !important;
  width: 100%;

  background-color: #ffffff;
}

textarea {
  resize: vertical;
}

.form-control:disabled {
  background-color: #ffffff;
  opacity: 1;
}

textarea {
  overflow-wrap: break-word;
}

element.style {
  font: 3em sans-serif;
}

.fkdocuments h1.tittel {
  color: #000000;
  font: 3em sans-serif;
  font-weight: 600;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.blue-background {
  background-color: #154e78;
  color: #ffffff;
  padding: 10px;
  box-shadow: 0 0 20px rgba(0,0,0,0.4);
  margin-bottom: 10px;
}
.blue-background button {
    background-color: #fff3;
    color: #ffffff;
    padding: 3px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em;
}

.x-row {
  display: flex;
  flex-direction: row;
}

.x-column{
    display: flex;
    flex-direction: column;
}
.align-center {
    align-items: center;
}
.space-between {
    justify-content: space-between;
}
.flex1 {
  flex: 1;
}
