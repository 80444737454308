div.clinicalUseDefinitions {
  position: fixed;
  bottom: 0;
  margin-left: -6px;
  width: 45%;
}

div.clinicalUseDefinitions div.card-header {
  background-color: rgb(195, 216, 228);

  font-weight: bold;

  /*
  background-image: url("data:image/svg+xml,%3Csvg width='50' height='50' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='25' cy='25' r='25' fill='red' /%3E%3C/svg%3E");
  background-position: calc(100% - 1em) center; /* 1em margin from the right */
  /* background-repeat: no-repeat;
  padding-right: 4em; /* space for the circle and its margin from the right */
  /* padding-top: 2em;
  padding-bottom: 2em;
  box-sizing: border-box;
*/
}
