body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.imgLogo {
  float: right !important;
  max-width: 300px !important;
  margin: 20px 0 !important;
}
.marginTop {
  margin: 40px 0 !important;
}
h1 {
  font-size: 1.4em !important;
  font-weight: bolder !important;
  padding-top: 20px;
  padding-bottom: 0px;
}
h2 {
  font-size: 1.2em !important;
  font-weight: bolder !important;
  padding-top: 20px;
  padding-bottom: 0px;
}

h3 {
  padding-top: 10px;
  font-size: 1em !important;
  font-weight: bolder !important;
}

.hidden {
  display: none;
}
