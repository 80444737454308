.home,
.back,
.add-item,
.submit-button {
  cursor: "pointer";
  color: "white";
  background-color: #154e78;
  transition: background-color 0.2s ease; /* Smooth transition */
  margin: 20px 0; /* applies 20px margin to both top and bottom */
}
.add-item:hover,
.home:hover,
.back:hover,
.submit-button:hover {
  background-color: #c3d8e4;
  color: black;
}
.close-button {
  cursor: "pointer";
  background-color: transparent;
  border-color: transparent;
}

.close-button:hover {
  transition: all 0.3s ease 0s;
  border-radius: 50px;
  border-color: #154e78;
}
.deleteButton {
  margin-right: 10px;
}

.secondaryCta,
.showFhir,
.showFKText {
  cursor: "pointer";
  color: black;
  background-color: white;
  transition: background-color 0.2s ease; /* Smooth transition */
  margin: 10px 10px 10px 0;
}
.secondaryCta:hover,
.showFhir:hover,
.showFKText:hover {
  background-color: #c3d8e4;
  color: black;
}

.critical {
  color: red;
}

.warning {
  color: rgb(206, 191, 34);
}

.default {
  color: black;
}
.footer {
  margin-top: 100px;
}
.floatRight {
  float: right;
}

.conditionList:first-letter,
.termNorwegian:first-letter {
  text-transform: capitalize;
}
.conditionList,
.termNorwegian {
  display: inline-block;
}
.conditionListHidden {
  margin-top: 10px;
}

.patientDataTop {
  margin: 10px;
  font-weight: bold;
}

.observations,
.conditions,
.medications {
  margin-top: 10px;
  background-color: white !important;
  border-style: solid;
  border-width: 1px;
  border-color: #154e78;
  margin-right: 10px;
}
.react-autosuggest__suggestions-container {
  color: black !important;
}

.wrapper {
  padding-bottom: 100px;
}
.innerWrapper.wrapper {
  padding-bottom: 40px;
}

.observationValue {
  margin-bottom: 0px;
  display: inline;
}
.metadata {
  background-color: #c3d8e4;
  padding: 10px;
  color: #505050;
}
.cdsCard {
  color: darkmagenta;
}

/* GPT CHAT css*/

/* Legg til dette i din CSS-fil */
.gpt-card {
  background-color: brown;
}

.chat-container {
  display: flex;
  flex-direction: column;
  max-width: 600px; /* Juster etter behov */
  margin: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
}

.chat-history {
  flex-grow: 1;
  max-height: 450px; /* Juster etter behov */
  padding: 6px;
  overflow-y: auto; /* Aktiverer rulling */
  margin-bottom: 2px; /* Legger til litt plass under chat-historikken */
}

.user-message {
  background-color: #e7f3ff; /* En lys farge for brukermeldinger */
  padding: 1px;
  padding-top: 9px;
  margin: 5px 0;
  border-radius: 5px;
  text-align: right;
  padding-right: 15px;
  font-size: 0.8em;
}

.bot-message {
  background-color: #f1f1f1; /* En annen farge for botmeldinger */
  padding: 1px;
  margin: 5px 0;
  border-radius: 10px;
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 0.8em;
}

.chat-input-container {
  display: flex;
  justify-content: space-between; /* Dette sørger for at barn (tekstfelt og knapp) fordeler plass */
  align-items: center;
  padding: 3px;
}

.chat-textarea {
  width: 85%; /* Gjør tekstboksen full bredde av containeren */
  height: auto; /* Gir mer plass for å skrive lengre meldinger */
  padding: 5px; /* Litt polstring for estetikk */
  resize: none; /* Fjerner muligheten for brukeren å endre størrelsen på tekstboksen */
  font-size: 0.9em;
}

/* Send button */
.send-button {
  padding: 12.5px 25px;
  border: none;
  border-radius: 4px;
  background-color: #515356;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 10px;
}

.send-button:hover {
  background-color: #727476;
}

.send-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Legg til denne CSS-en i din index.css fil eller en relevant CSS-fil */
@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.loader {
  display: inline-block;
  margin-left: 5px;
}

.loader-dot {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: black;
  margin: 0 2px;
}

.loader-dot:nth-child(1) {
  animation-delay: 0s;
}
.loader-dot:nth-child(2) {
  animation-delay: 0.2s;
}
.loader-dot:nth-child(3) {
  animation-delay: 0.4s;
}

/* GPT CHAT css slutt*/
