.App {
  overflow: hidden;
}

.react-autosuggest__container {
  position: relative;
}

.appbtn {
  margin-left: 10px;
}

.show {
  float: right;
}

.pulsecard {
  background-color: #96c1c882;
  margin-bottom: 10px;
  border-color: #619ca5;
  margin-top: 10px;
  /* max-width: 50%; */
  --bs-card-border-color: none;
}

.react-autosuggest__input {

  width: 620px;
  height: 38px;
  padding: 0.375rem 0.75rem;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.jsondata {
  overflow-y: scroll;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 37px;
  width: 630px;
  border: 1px solid #ced4da;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0.375rem 0.75rem;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}