/* Loader */
.backdrop-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 98;
  }
  .backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #0000007e;
  }